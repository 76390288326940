body {
  background-image: url('../../assets/img/bk.jpg');
  background-size: cover;
}
#main-container {
  width: 100%;
  min-height: 100vh;  
  display: flex;
  padding: 0 40px 40px 40px;
  flex-direction: column;
  align-items: center;
}
#bk-main-logo {
  background-image: url('../../assets/img/logo_white.png');
  background-size: contain;
  width: calc(100vw /2);
  max-width: 400px;
  position: absolute;
  top: calc(15vh);
  opacity: 0.5;
}
header {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  background-color: #000000CC;
  color: #fff;
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: center;
}
.header-container {
  max-width: 1144px;
  flex: 1;
  padding: 0 12px 0 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#header-logo {
  width: 54px;  
  position: absolute;
  left: 12px;
  top: 4px;  
}
#main-container.mobile {
  padding: 0px;
}
.body-iframe {
  margin-top: 50px;
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
  max-width: 1144px;
}
footer {
}
footer.isSafari {
  height: 120px  
}
footer.mobile {
  height: 54px
}
footer.mobile.pwa {
  height: 0px
}